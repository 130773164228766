<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('monte.bilan_saison')"/>

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                         <div class="box">
							<SeasonInput v-model="seasonSelected"/>

				            <div class="form-group mb-4 mt-4">
								<DateRangeV2
									:start.sync="start_date"
									:end.sync="end_date"
									@submit="getBilan"
									:dateSelect=true
								/>
                                <div class="custom-control custom-checkbox mt-4">
                                    <input type="checkbox" class="custom-control-input" id="get_invoice" v-model="get_invoice">
                                    <label class="custom-control-label" for="get_invoice">{{ $t('monte.show_facture_info') }}</label>
                                </div>
                            </div>

							<ErrorAlert v-if="message_offline !== ''" :messageI18n="message_offline" />

					        <CustomTable
					            id_table="bilan_saison"
					            :items="saison_monte"
					            :busy.sync="table_busy"
								primaryKey="uid"
								:hrefsRoutes="config_table_hrefs"
					        />
					    </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script type="text/javascript">
    import Contract from "@/mixins/Contract.js"
    import Common from '@/assets/js/common.js'

	export default {
		name: 'BilanMonte',
		mixins: [Contract],
		data () {
			return {
				saison_monte: [],
				table_busy: false,
				message_offline: "",
				config_table_hrefs: {
                    'stallion.horse_nom': {
						routeUniqueName: 'StallionFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'stallion.horse_id'
                        }
					}
                },
                seasonSelected: null,
				start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
                end_date: new Date(),
                get_invoice: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
			},

			async getBilan() {
				if(!this.seasonSelected) return 

				this.table_busy = true
				let saison_monte = await this.loadSaisonMonte(this.seasonSelected.id, this.get_invoice, this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
				this.saison_monte = []

				saison_monte.forEach(async saison => {
					saison.uid = Common.getRandomString(5)
					saison.type_monte = this.getTrad(saison.type_monte)
					saison.ca_estime = await this.priceFormat(saison.ca_estime/100)
					saison.ca_estime_ttc = await this.priceFormat(saison.ca_estime_ttc/100)
					saison.resa_facture = await this.priceFormat(saison.resa_facture/100)
					saison.resa_facture_ttc = await this.priceFormat(saison.resa_facture_ttc/100)
					saison.saillie = await this.priceFormat(saison.saillie/100)
					saison.saillie_ttc = await this.priceFormat(saison.saillie_ttc/100)
					this.saison_monte.push(saison)
				})

				if(!window.navigator.onLine && this.get_invoice) {
					this.message_offline = this.getTrad('monte.bilan_offline')
				}

				this.table_busy = false
			}
		},
		watch: {
			get_invoice() {
				this.getBilan()
			}
		},


		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'), 
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            SeasonInput: () => import('@/components/Inputs/SeasonInput'),
			DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
		}
	}

</script>